import React from 'react'

const Input = ({ id, type, desc, label, handleChange, subs, initial, mode }) => {
  let initialValue = initial;
  if(mode=='array') {
    initialValue = initial?initial.map((item,index) => item['col'+index]):[];
  } else if (mode == 'arrayObj') {
    /*
    Array [ {…}, {…} ]
​
0: Object { col0: "asd", col1: "sdf", col2: "sad" }
​
1: Object { col0: "gfdfg", col1: "sdfgfg", col2: "ewer" }
​
    
    */
    let string = '';
    initial&&initial.forEach((item,index) => {
      let arrColumns = Object.keys(item).map(key => {
        if(typeof item[key]==='boolean') {
          return item[key]?'is_regex':'';
        }
        return item[key];
      })
      string+=arrColumns.toString();
      if (index !== initial.length - 1){ 
        string+=';';
      }
    })


    initialValue = initial?string:[];
  }
  return (
    <>
        <div className="mb-3">
            <label htmlFor={ id } className="form-label">{ label }</label>
            <input type={ type } autoComplete="off" className="form-control" id={ id } name={ id } aria-describedby={ 'desc_'+id } data-subs={subs} data-mode={mode} onChange={ handleChange } value={initialValue}/>
            {desc&&<div id={ 'desc_'+id } className="form-text">{ desc }</div>}
        </div>
    </>
  )
}

export default Input