import React from 'react'

const Textarea = React.forwardRef(({id, label, rows}, ref) => {
  return (
    <div className="form-group my-4">
        <label htmlFor={id}>{label}</label>
        <textarea ref={ref} className="form-control" id={id} rows={rows||3}></textarea>
    </div>
  )
});

export default Textarea