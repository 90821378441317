import React, {useReducer} from "react";

const createContext = (reducer, actions, initialState) => {

    const Context = React.createContext();

    const Provider = ({children}) => {
        const [state, dispatch] = useReducer(reducer,initialState);

        const boundActions = {};
        for(let key in actions){
            boundActions[key] = actions[key](dispatch);
        }


        return (
            <Context.Provider value={{ state, ...boundActions }}>
                {children}
            </Context.Provider>
        );
    }

    return { Context: Context, Provider: Provider };
};

export default createContext;