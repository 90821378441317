import React from 'react'

const Select = ({ id, desc, label, options, handleChange, children, subs, initial }) => {
/*
    options = ['aaaa', 'bbbb', ...]
*/
  return (
    <>
        <div className="mb-3">
            <label htmlFor={ desc } className="form-label">{ label }</label>
            <select autoComplete="off" className="form-select" aria-label={ 'desc_' + id } id={ id } name={ id } data-subs={subs} onChange={ handleChange }  defaultValue={initial}>
                {options?.map((option, index) => {
                    let value = '';
                    let name = option.name ? option.name : (option.value ? option.value : option);

                    if(option.value||option.name) {
                        value = option.value||option.name;
                    } else {
                        //value = 'opt_'+option.toLowerCase().trim().replaceAll(' ','_');
                        value = option.toLowerCase().trim().replaceAll(' ','_');
                    }

                    return <option key={index} value={ value }>{ name }</option>
                }
                    
                )}
                {children}
            </select>
            {desc&&<div id={ 'desc_' + id } className="form-text">{ desc }</div>}
        </div>
    </>
  )
}

export default Select