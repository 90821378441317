export const generateText = (titolare, sede, email, ec) => {
    return `<!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>INFORMATIVA PRIVACY ECOMMERCE-IN YOUR LIFE-09.22</title>
    </head>
    <body>
        <div>
            <p>La presente informativa viene resa in ossequio all'art. 13 del Regolamento 2016/679 (GDPR), ai sensi dell'art. 13 del d.lgs. n. 196/2003 (Codice in materia di protezione dei dati personali) ed è relativa a tutti i dati personali trattati secondo le modalità di seguito indicate.</p>
        <h3>Titolare del Trattamento dei Dati</h3>
        <p>
            Il Titolare del trattamento dei <u>Dati Personali raccolti</u> è: ${titolare}
        </p>
        <p>
            Sede operativa: ${sede}
        </p>
        <p>
            Indirizzo email del Titolare: ${email}
        </p>
        <h3>Tipologie di Dati raccolti</h3>
    <p>
       Dettagli completi su ciascuna tipologia di dati raccolti sono forniti nelle sezioni dedicate di questa privacy policy.
    </p>
    <p>
       I Dati Personali possono essere liberamente forniti dall’Utente o, nel caso di Dati di Utilizzo, raccolti automaticamente durante l’uso del sito.
    </p>
    <p>
       Se non diversamente specificato, tutti i Dati richiesti sono obbligatori. Se l’Utente rifiuta di comunicarli, potrebbe essere impossibile fornire il servizio. Nei casi in cui alcuni dati siano facoltativi, gli Utenti sono liberi di astenersi dal comunicare tali Dati, senza che ciò abbia alcuna conseguenza sulla disponibilità del Servizio o sulla sua operatività.
    </p>
    <p>
       Gli Utenti che dovessero avere dubbi su quali Dati siano obbligatori, sono incoraggiati a contattare il Titolare.
    </p>
    <p>
       L’eventuale utilizzo di Cookie, o di altri strumenti di tracciamento, da parte del sito o dei titolari dei servizi terzi utilizzati, ove non diversamente precisato, ha la finalità di fornire il Servizio richiesto dall’Utente.
    </p>
    <p>
       L’Utente si assume la responsabilità dei Dati Personali di terzi ottenuti, pubblicati o condivisi mediante il sito e garantisce di avere il diritto di comunicarli o diffonderli, liberando il Titolare da qualsiasi responsabilità verso terzi.
    </p>
    <h2>MODALITÀ E LUOGO DEL TRATTAMENTO DEI DATI RACCOLTI</h2>
    <h3>Finalità del Trattamento dei Dati raccolti</h3>
    <p>
        ${ec ?
            `I dati dell’utente sono raccolti per consentire al Titolare di fornire i propri Servizi, così come per le seguenti finalità: Statistica, Newsletter, Pubblicità Personalizzata, Accounting, Acquisti, Test di performance di contenuti e funzionalità, Interazione con social network e piattaforme esterne, Visualizzazione di contenuti da piattaforme esterne e interazione con piattaforme di raccolta dati e altre terze parti.`
            :
            `I dati dell’utente sono raccolti per consentire al Titolare di fornire i propri Servizi, così come per le seguenti finalità: Statistica, Newsletter, Pubblicità Personalizzata, Accounting, Test di performance di contenuti e funzionalità, Interazione con social network e piattaforme esterne, Visualizzazione di contenuti da piattaforme esterne e interazione con piattaforme di raccolta dati e altre terze parti.`    
        }
    
    </p>
    <p>
       Per ottenere ulteriori informazioni dettagliate sulle finalità del trattamento e sui Dati Personali concretamente rilevanti per ciascuna finalità, l’Utente può fare riferimento alle relative sezioni di questo documento.
    </p>
    <h3>Modalità di trattamento</h3>
    <p>
       Il Titolare adotta le opportune misure di sicurezza volte ad impedire l’accesso, la divulgazione, la modifica o la distruzione non autorizzate dei Dati Personali.
    </p>
    <p>
       Il trattamento viene effettuato mediante strumenti informatici e/o telematici, con modalità organizzative e con logiche strettamente correlate alle finalità indicate. Oltre al Titolare hanno accesso ai dati altri soggetti esterni all’azienda (personale amministrativo, commerciale, marketing, legali, amministratori di sistema) ovvero soggetti esterni (come fornitori di servizi tecnici terzi, corrieri postali, hosting provider, società informatiche, agenzie di comunicazione) nominati anche, se necessario, Responsabili del Trattamento da parte del Titolare. L’elenco aggiornato dei Responsabili potrà sempre essere richiesto al Titolare del Trattamento.
    </p>
    <h3>Base giuridica del trattamento</h3>
    <p>
       Il Titolare tratta Dati Personali relativi all’Utente in caso sussista una delle seguenti condizioni:
    </p>
    <ul>
       <li>
          l’utente ha prestato il consenso per una o più finalità specifiche; 
       </li>
       <li>
          il trattamento è necessario all’esecuzione di un contratto con l’utente e/o all’esecuzione di misure precontrattuali;
       </li>
       <li>
          il trattamento è necessario per adempiere un obbligo legale al quale è soggetto il Titolare;
       </li>
       <li>
          il trattamento è necessario per l’esecuzione di un compito di interesse pubblico o per l’esercizio di pubblici poteri di cui è investito il Titolare;
       </li>
       <li>
          il trattamento è necessario per il perseguimento del legittimo interesse del Titolare o di terzi.
       </li>
    </ul>
    <p>
       È comunque sempre possibile richiedere al Titolare di chiarire la concreta base giuridica di ciascun trattamento ed in particolare di specificare se il trattamento sia basato sulla legge, previsto da un contratto o necessario per concludere un contratto.
    </p>
    <h3>Luogo</h3>
    <p>
       I Dati sono trattati presso le sedi operative del Titolare ed in ogni altro luogo in cui le parti coinvolte nel trattamento siano localizzate. Per ulteriori informazioni, contatta il Titolare.
    </p>
    <p>
       I Dati Personali dell’Utente potrebbero essere trasferiti in un paese diverso da quello in cui l’Utente si trova. Per ottenere ulteriori informazioni sul luogo del trattamento l’Utente può fare riferimento alla sezione relativa ai dettagli sul trattamento dei Dati Personali.
    </p>
    <p>
       L’Utente ha diritto a ottenere informazioni in merito alla base giuridica del trasferimento di Dati al di fuori dell’Unione Europea o ad un’organizzazione internazionale di diritto internazionale pubblico o costituita da due o più paesi, come ad esempio l’ONU, nonché in merito alle misure di sicurezza adottate dal Titolare per proteggere i Dati.
    </p>
    <p>
       Qualora abbia luogo uno dei trasferimenti appena descritti, l’Utente può fare riferimento alle rispettive sezioni di questo documento o chiedere informazioni al Titolare contattandolo agli estremi riportati in apertura.
    </p>
    <h3>Periodo di conservazione</h3>
    <p>
       I Dati sono trattati e conservati per il tempo richiesto dalle finalità per le quali sono stati raccolti:
    </p>
    <ul>
       <li>
          I <u>Dati Personali raccolti</u> per scopi collegati all’esecuzione di un contratto tra il Titolare e l’Utente saranno trattenuti sino a quando sia completata l’esecuzione di tale contratto.
       </li>
       <li>
          I <u>Dati Personali raccolti</u> per finalità riconducibili all’interesse legittimo del Titolare saranno trattenuti sino al soddisfacimento di tale interesse. L’Utente può ottenere ulteriori informazioni in merito all’interesse legittimo perseguito dal Titolare nelle relative sezioni di questo documento o contattando il Titolare.
       </li>
    </ul>
    <p>
       Quando il trattamento è basato sul consenso dell’Utente, il Titolare può conservare i Dati Personali più a lungo sino a quando detto consenso non venga revocato. Inoltre il Titolare potrebbe essere obbligato a conservare i Dati Personali per un periodo più lungo in ottemperanza ad un obbligo di legge o per ordine di un’autorità.
    </p>
    <p>
       Al termine del periodo di conservazioni i Dati Personali saranno cancellati. Pertanto, allo spirare di tale termine il diritto di accesso, cancellazione, rettificazione ed il diritto alla portabilità dei Dati non potranno più essere esercitati.
    </p>

    <h3>Dettagli sul trattamento dei Dati Personali</h3>
    <p>
        I Dati Personali sono raccolti per le seguenti finalità ed utilizzando i seguenti servizi:
    </p>
    <h4><em>Mailing List o Newsletter</em></h4>
    <p>
        Con la registrazione alla mailing list o alla newsletter, l’indirizzo email dell’Utente viene automaticamente inserito in una lista di contatti a cui potranno essere trasmessi messaggi email contenenti informazioni, anche di natura commerciale e promozionale. L’indirizzo email dell’Utente potrebbe anche essere aggiunto a questa lista come risultato della registrazione al sito o dopo aver richiesto un preventivo.
    </p>
    <p>
        <u>Dati Personali raccolti</u>: email, nome e cognome.
    </p>
    <h4><em>Modulo di contatto</em></h4>
    <p>
        L’Utente, compilando con i propri Dati il modulo di contatto, acconsente al loro utilizzo per rispondere alle richieste di informazioni, di preventivo, o di qualunque altra natura indicata dall’intestazione del modulo.
    </p>
    <p>
        <u>Dati Personali raccolti</u>: nome e cognome, email (dati obbligatori), telefono, e varie tipologie di Dati.
    </p>
    <h4><em>Registrazione per accesso ad area riservata</em></h4>
    <p>
    L’Utente, compilando con i propri Dati il modulo di registrazione, acconsente al loro utilizzo per creare un account univoco utilizzabile per le varie funzioni del sito (rispondere alle richieste di informazioni, di preventivo, o e-commerce).
    </p>
    <p>
    <u>Dati Personali raccolti</u>: nome e cognome, email (dati obbligatori), telefono, e varie tipologie di Dati.
    </p>
    <h4><em>Interazione con social network e piattaforme esterne</em></h4>
    <p>
    Questo tipo di servizi permette di effettuare interazioni con i social network, o con altre piattaforme esterne, direttamente da questo sito. Le interazioni e le informazioni acquisite sono in ogni caso soggette alle impostazioni privacy dell’Utente relative ad ogni social network.
    </p>
    <p>
    Nel caso in cui sia installato un servizio di interazione con i social network, è possibile che, anche nel caso gli Utenti non utilizzino il servizio, lo stesso raccolga dati di traffico relativi alle pagine in cui è installato.
    </p>
    <ul>
    <li>
        <h5>Widget sociali di Facebook (Facebook Inc.)</h5>
        <p>
            I widget sociali di Facebook sono servizi di interazione con il social network Facebook, forniti da Facebook, Inc. 
        </p>
        <p>
            <u>Dati Personali raccolti</u>: Cookie e Dati di utilizzo. Luogo del trattamento: USA – Privacy Policy.
        </p>
    </li>
    <li>
        <h5>Widget sociali di YouTube (Google Inc.)</h5>
        <p>
            I widget sociali di YouTube sono servizi di interazione con la piattaforma di YouTube, forniti da Google Inc.
        </p>
        <p>
            <u>Dati Personali raccolti</u>: Cookie e Dati di utilizzo. Luogo del trattamento: USA – Privacy Policy.
        </p>
    </li>
    </ul>

    <h4><em>Remarketing e Behavioral targeting</em></h4>
    <p>
    Questo tipo di servizi consente di comunicare, ottimizzare e servire annunci pubblicitari basati sull’utilizzo passato di questo sito da parte dell’Utente. Questa attività viene effettuata tramite il tracciamento dei Dati di Utilizzo e l’uso di Cookie, informazioni che vengono trasferite ai partner a cui l’attività di remarketing e behavioral targeting è collegata.
    </p>
    <ul>
    <li>
        <h5>Remarketing con Google Analytics per la pubblicità display (Google Inc.)</h5>
        <p>Google Analytics per la pubblicità display è un servizio di remarketing e behavioral targeting fornito da Google Inc. che collega l’attività di tracciamento effettuata da Google Analytics e dai suoi Cookie. 
            <u>Dati Personali raccolti</u>: Cookie e Dati di utilizzo. Luogo del trattamento: USA – Privacy Policy.
        </p>
    </li>
    </ul>
    <h4><em>Statistica</em></h4>
    <p>
    I servizi contenuti nella presente sezione permettono al Titolare del Trattamento di monitorare e analizzare i dati di traffico e servono a tener traccia del comportamento dell’Utente.
    </p>
    <ul>
    <li>
        <h5>Google Analytics (Google Inc.)</h5>
        <p>Google Analytics è un servizio di analisi web fornito da Google Inc. (“Google”). Google utilizza i <u>Dati Personali raccolti</u> allo scopo di tracciare ed esaminare l’utilizzo del sito, compilare report e condividerli con gli altri servizi sviluppati da Google. Google potrebbe utilizzare i Dati Personali per contestualizzare e personalizzare gli annunci del proprio network pubblicitario. 
            <u>Dati Personali raccolti</u>: Cookie e Dati di utilizzo. Luogo del trattamento: USA – Privacy Policy – Opt Out.
        </p>
    </li>
    </ul>
    ${ec ? `<h4><em>Acquisti in E-Commerce</em></h4>
    <p>
    I servizi contenuti nella presente sezione permettono all’utente di acquistare i prodotti disponibili del sito. Questa attività viene effettuata tramite il pagamento con PayPal.
    </p>
    <ul>
    <li>
        PayPal (PayPal (Europe) S.à r.l. et Cie, S.C.A.)
    </li>
    </ul>` : '' }
    <h3>Ulteriori informazioni sui Dati Personali</h3>
    <h4><em>Privacy Policy</em></h4>
    <p>
    L’Utente, compilando con i propri Dati il modulo di contatto, acconsente al loro utilizzo per rispondere alle richieste di informazioni, di preventivo, o di qualunque altra natura indicata dall’intestazione del modulo.
    </p>
    <h4><em>Diritti dell’Utente</em></h4>
    <p>Ai sensi degli artt. 15 – 21 del Regolamento UE 2016/679 a ciascun interessato vengono riconosciuti una serie di diritti.</p>
    <ul>
    <li>
        <u>Diritto di Accesso:</u> l’interessato, ai sensi dell’art. 15, ha diritto di ottenere la conferma che sia in corso un trattamento di dati personali che lo riguardano e, se del caso, di ottenerne copia. Ha inoltre il diritto di ottenere l’accesso ai dati personali che lo riguardano e a ulteriori informazioni quali la finalità del trattamento, le categorie di destinatari, il periodo di conservazione dei dati e i diritti esercitabili.
    </li>
    <li>
        <u>Diritto di rettifica:</u> l’interessato, ai sensi dell’art. 16, ha diritto di ottenere la rettifica dei dati personali inesatti che lo riguardano o l’integrazione degli stessi.
    </li>
    <li>
        <u>Diritto alla cancellazione:</u> l’interessato ha diritto di ottenere la cancellazione dei dati personali che lo riguardano, senza ingiustificato ritardo, qualora sussista una dei motivi previsti dall’art. 17.
    </li>
    <li>
        <u>Diritto di limitazione di trattamento:</u> l’interessato ha diritto, nei casi previsti dall’art 18 del Regolamento 2016/679, di ottenere la limitazione del trattamento.
    </li>
    <li>
        <u>Diritto alla portabilità dei dati:</u> l’interessato ha diritto di ricevere in un formato strutturato, di uso comune e leggibile da dispositivo automatico, i dati personali che lo riguardano e ha il diritto di trasmettere tali dati a un altro titolare senza impedimento, secondo quanto previsto dall’art. 20 del Regolamento 2016/679;
    </li>
    <li>
        <u>Diritto di opposizione al trattamento:</u> l’interessato ha diritto di opporsi al trattamento dei dati personali che lo riguardano secondo quanto previsto dall’art. 21 del Regolamento 2016/679.
    </li>
    </ul>
    <p>L’interessato ha altresì diritto di proporre reclamo all’Autorità di controllo competente, il Garante della Privacy. </p>
    <p>Le richieste di cui ai punti precedenti dovranno essere rivolte per iscritto al Titolare del Trattamento. Il Titolare del trattamento provvederà, nei limiti temporali stabiliti dalla vigente normativa, a fornire tempestiva risposta alle richieste di esercizio dei diritti degli interessati.</p>



 </div>
 
</body>
</html>`
}