import createContext from '../../context/createContext';

const initialState = {
    test: 0,
}

const reducer = ( state, action ) => {
  switch (action.type) {
    case "setTest":
      return { ...state, test: action.payload };
    default:
      return state;
  }
}

const setTest = (dispatch) => {
  return function( test ){
    dispatch({ type: 'setTest', payload: test } )
  }
}


const actions = {
  setTest
};



export const {Provider, Context} = createContext( reducer, actions, initialState);