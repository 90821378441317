import React from 'react'
import { NavLink } from 'react-router-dom';

const Home = ({children}) => {
  return (
    <>
        <ul className="nav nav-pills justify-content-center my-4">
            <li className="nav-item">
                <NavLink className={({isActive}) => "nav-link" + (isActive?' active':'')} to="..\cookies">Configuratore Cookies</NavLink>
            </li>
            <li className="nav-item">
                <NavLink className={({isActive}) => "nav-link" + (isActive?' active':'')} to="..\privacy">Configuratore Privacy</NavLink>
            </li>
        </ul>
        { children }
    </>
  )
}

export default Home


