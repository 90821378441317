import { BrowserRouter, Route, Routes } from "react-router-dom";

import { Provider as TestProvider} from './context/features/TestContext'
import { Navigate } from "react-router-dom";

import Test from './components/Test';
import CookiesConfigurator from "./pages/CookiesConfigurator";
import PrivacyConfigurator from "./pages/PrivacyConfigurator";
import Home from "./layouts/Home";

import './App.css';

function App() {
  return (
    <TestProvider>
      <BrowserRouter>
        <Routes>
          <Route exac path="/"  element={<Navigate to="../cookies"/>}/>
          <Route path="/cookies"  element={<CookiesConfigurator/>}/>
          <Route path="/privacy"  element={<PrivacyConfigurator/>}/>
        </Routes>
      </BrowserRouter>
    </TestProvider>
  );
}

export default App;
