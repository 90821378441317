export const cookieJSONbase = {
    "current_lang": "it",
    "autoclear_cookies": true,
    "page_scripts": true,
    "mode": "opt-in",
    "delay": 0,
    "auto_language": null,
    "autorun": true,
    "force_consent": true,
    "hide_from_bots": true,
    "remove_cookie_tables": false,
    "gui_options": {
        "consent_modal": {
            "layout": "cloud",
            "position": "middle center",
            "transition": "slide",
            "swap_buttons": false
        },
        "settings_modal": {
            "layout": "box",
            "position": "left",
            "transition": "zoom"
        }
    },
    "languages": {
        "it": {
            "consent_modal": {
                "title": "Consenso Cookies",
                "description": "Questo sito utilizza cookies per migliorare l'esperienza di navigazione (vedi <a href=\"/privacy-policy/\" target=\"_blank\" rel=\"noopener\">Privacy Policy</a>).",
                "primary_btn": {
                    "text": "Accetta tutti",
                    "role": "accept_all"
                },
                "secondary_btn": {
                    "text": "Gestisci cookies",
                    "role": "settings"
                }
            },
            "settings_modal": {
                "title": "Preferenze Cookies",
                "save_settings_btn": "Salva impostazioni",
                "accept_all_btn": "Accetta tutti",
                "reject_all_btn": "Rifiuta tutti",
                "close_btn_label": "Chiudi",
                "cookie_table_headers": [{
                    "col0": "Nome"
                }, {
                    "col1": "Dominio"
                }, {
                    "col2": "Scadenza"
                }, {
                    "col3": "Descrizione"
                }],
                "blocks": [{
                    "title": "Utilizzo Cookies",
                    "description": "Utilizziamo i cookies per migliorare l'esperienza di navigazione dell'utente. Puoi scegliere quali cookies di profilazione abilitare o non. Per ulteriori dettagli consulta la nostra <a href=\"/privacy-policy\" target=\"_blank\" rel=\"noopener\">Cookie Policy.</a>"
                }, {
                    "title": "Cookies Analytics",
                    "description": "",
                    "toggle": {
                        "value": "analytics",
                        "enabled": false,
                        "readonly": false
                    },
                    "cookie_table": [{
                        "is_regex": true,
                        "col0": "^_ga",
                        "col1": "websitedomain.com",
                        "col2": "1 anno",
                        "col3": "analizzare i dati di traffico"
                    }]
                }, {
                    "title": "Cookies Necessari",
                    "description": "",
                    "toggle": {
                        "value": "cookieconsent",
                        "enabled": true,
                        "readonly": true
                    },
                    "cookie_table": [{
                        "col0": "cc_cokie",
                        "col1": "websitedomain.com",
                        "col2": "6 mesi",
                        "col3": "consenso cookies"
                    }]
                }]
            }
        }
    }
}
