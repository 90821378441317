import React, { useState } from 'react'
import { Input, Checkbox } from '../components/forms';
import { saveAs } from 'file-saver';
import { generateText } from '../resources/texts/privacy';
import WithLayout from '../hoc/WithLayout';

const INITIAL_STATE = {
    titolare : '',
    sede : '',
    email : '',
    isEcommerce : false
}


const PrivacyConfigurator = () => {
    const [formData, setFormData] = useState(INITIAL_STATE);
    
    const handleChange = (e) => {
        const { name, type } = e.target;
       
        
        let value = '';
        
        switch (type) {
            case 'checkbox':
                value = e.target.checked;
                break;
            default:
                value = e.target.value;
        }
        
        setFormData((prevState)=>({
            ...prevState,
            [name] : value
        }));
    }
    
   
    const saveFile = () => {
        const data = generateText(formData.titolare, formData.sede, formData.email, formData.isEcommerce);
        const blob = new Blob([data], {type: 'text/html'});
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'privacy.html';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

   

    return (
        <>
        <div className="container my-4 py-4">
             
            <h2>privacy</h2>
            <Input id='titolare' initial={formData.titolare} type='text' desc='' label='Titolare' handleChange={ handleChange } />
            <Input id='sede' initial={formData.sede} type='text' desc='' label='Sede' handleChange={ handleChange } />
            <Input id='email' initial={formData.email} type='text' desc='' label='Email' handleChange={ handleChange } />
            <Checkbox id='isEcommerce' initial={formData.isEcommerce} desc='' label='E-commerce' handleChange={ handleChange } />
            <h2>code</h2>
            
            <code><pre>{JSON.stringify(formData, null, 2)}</pre></code>

            <button className="btn btn-primary" onClick={saveFile}>SALVA</button>
        </div>
        </>
    )
}


export default WithLayout(PrivacyConfigurator);