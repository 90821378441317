import React, { Component } from 'react'
import Home from '../layouts/Home';

const WithLayout = (Component) => ({...props}) => {
  return (
    <Home>
        <Component {...props} />
    </Home>
  )
}

export default WithLayout;
