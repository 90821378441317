import React from 'react'

const Radio = ({ id, options, handleChange, subs, initial }) => {
    /*
        options = ['radio1', 'radio2']
    */
  return (
    <>
        <div className="mb-3">
            {options?.map((option,index) => {
                let idOption = id+index;
                let name = option.name ? option.name : (option.value ? option.value : option);
                if(option.value||option.name) {
                    idOption = option.value||option.name;
                }
                return <div key={index} className="form-check">
                        <input className="form-check-input" type="radio" autoComplete="off" checked={initial===idOption} name={ id } id={idOption}  data-subs={subs} onChange={ handleChange }/>
                        <label className="form-check-label" htmlFor={idOption}>
                            {name}
                        </label>
                    </div>
            })}
        </div>
    </>
  )
}

export default Radio