import React, { useState, useRef } from 'react'
import { Input, Checkbox, Select, Radio, Textarea } from '../components/forms';
import {Accordion, AccordionItem} from '../components/Accordion';
import { saveAs } from 'file-saver';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import WithLayout from '../hoc/WithLayout';
import { cookieJSONbase } from '../resources/json/cookiejsonbase';

const ALLOWED_LANGS = ['en', 'es', 'fr', 'de', 'it', 'pt'];

const LANGUAGE_TEMPLATE = {
    consent_modal: {
        title: '',
        description: '',
        primary_btn: {
            text: '',
            role: 'accept_all'              // 'accept_selected' or 'accept_all'
        },
        secondary_btn: {
            text: '',
            role: 'accept_necessary'        // 'settings' or 'accept_necessary'
        }
    },
    settings_modal: {
        title: '',
        save_settings_btn: '',
        accept_all_btn: '',
        reject_all_btn: '',
        close_btn_label: '',
        cookie_table_headers: [],
        blocks: []
    }
}

let BLOCK_TABLE_TEMPLATE = {
    title: '',
    description: '',
    toggle: {
        value: '',     // your cookie category
        enabled: false,
        readonly: false
    },
    cookie_table: []
}
let BLOCK_TEMPLATE = {
    title: '',
    description: '',
}

const INITIAL_STATE = {
    current_lang: 'it',
    autoclear_cookies: false,                   // default: false
    page_scripts: false,                        // default: false
    mode: 'opt-in',                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
    delay: 0,                               // default: 0
    auto_language: null,                    // default: null; could also be 'browser' or 'document'
    autorun: true,                          // default: true
    force_consent: false,                   // default: false
    hide_from_bots: false,                  // default: false
    remove_cookie_tables: false,             // default: false

    gui_options: {
        consent_modal: {
            layout: 'cloud',               // box/cloud/bar
            position: 'bottom center',     // bottom/middle/top + left/right/center
            transition: 'slide',           // zoom/slide
            swap_buttons: false            // enable to invert buttons
        },
        settings_modal: {
            layout: 'box',                 // box/bar
            position: 'left',           // left/right
            transition: 'zoom'            // zoom/slide
        }
    },

    languages : {
    }
}



const CookiesConfigurator = () => {
    const [formData, setFormData] = useState(INITIAL_STATE);
    const [languages, setLanguages] = useState([]);
    
    const lanRef = useRef('');
    const textareaRef = useRef('');


    const handleChange = (e) => {
        const { subs, mode } = e.target.dataset;
        const  name  = subs ? e.target.name.split('_'+subs.replaceAll(',','_'))[0] : e.target.name;
        
        let value = '';

        switch (e.target.type) {
            case 'checkbox':
                value = e.target.checked;
                break;
            case 'radio':
                value = e.target.id;
                break;
            default:
                value = e.target.value;
        }

        if(mode=='array') {
            value = value?value.split(',').map((item, index) => {return {["col"+index]:item}}):[];
        } else if (mode=='arrayObj') {
            //asd,sdf,sad;gfdfg,sdfgfg,ewer
            value = value
            ?
            value.split(';').map((row, index) => {
                let tmpObj = {};
                let countCol = 0;

                row.split(',').forEach((column)=>{
                    if(column=='is_regex') {
                        tmpObj['is_regex'] = true;
                    } else {
                        tmpObj["col"+(countCol++)] = column
                    }
                });
                return tmpObj;
                
            })
            
            :
            [];
            
            
        }
        
        setFormData((prevState)=>({
            ...prevState,
            [name] : (subs ? nestedKeys(prevState[name], subs.split(','), value) : value)
        }));

        const nestedKeys = (prevState, subs, value) => {
            const subsTemp = [...subs];
            //console.log(prevState)
            const shifted = subsTemp.shift();
            
            if(shifted.includes('-')) {
                let arrTemp = shifted.split('-');
                //console.log(arrTemp)
                const arrBlock = prevState[arrTemp[0]];
                arrBlock[arrTemp[1]] = ((subsTemp.length>=1) ? nestedKeys(prevState[arrTemp[0]][arrTemp[1]], subsTemp, value) : value);

                return {
                    ...prevState,
                    [arrTemp[0]]: arrBlock
                }
            }

            
            return {
                ...prevState,
                [shifted]: ((subsTemp.length>=1) ? nestedKeys(prevState[shifted], subsTemp, value) : value)
            }
        }
    }
    
    const addLanguage = () => {
        const lan = lanRef.current.value;
        if (ALLOWED_LANGS.includes(lan)&&!languages.includes(lan)) {
            setLanguages([...languages, lan]);
            setFormData((prevState)=>({
                ...prevState,
                languages : {
                    ...prevState.languages,
                    [lan] : LANGUAGE_TEMPLATE
                }
            }));
            
        }
        lanRef.current.value = ""
    }

    const removeLanguage = (lan) => {
        setLanguages(languages.filter(l => l!== lan));
        setFormData((prevState)=>{
            const {[lan]:_,...rest} = prevState.languages;
            return {
                ...prevState,
                languages : rest
            }
        });
        
    }

    const saveFile = () => {
        const data = 'var cc = initCookieConsent();cc.run('+JSON.stringify(formData)+');';
        const blobJS = new Blob([data], {type: 'text/javascript'});
        const urlJS = window.URL.createObjectURL(blobJS);
        const linkJS = document.createElement('a');
        linkJS.href = urlJS;
        linkJS.download = 'cookieconsent-init.js';
        document.body.appendChild(linkJS);
        linkJS.click();
        document.body.removeChild(linkJS);

        const blobJSON = new Blob([JSON.stringify(formData)], {type: 'text/json'});
        const urlJSON = window.URL.createObjectURL(blobJSON);
        const linkJSON = document.createElement('a');
        linkJSON.href = urlJSON;
        linkJSON.download = 'cookieconsent-JSON.json';
        document.body.appendChild(linkJSON);
        linkJSON.click();
        document.body.removeChild(linkJSON);
    }

    const addBlock = (lan, blockTemplate) => {
        
        setFormData((prevState)=>({
            ...prevState,
            languages : {
                ...prevState.languages,
                [lan] : {
                    ...prevState.languages[lan],
                    settings_modal: {
                        ...prevState.languages[lan].settings_modal,
                        blocks:[...prevState.languages[lan].settings_modal.blocks, blockTemplate]
                    }
                }
            }
        }));

    }

    const removeBlock = (lan,i) => {
        setFormData((prevState)=>{
            const arrTemp = [...prevState.languages[lan].settings_modal.blocks];
            arrTemp.splice(i, 1)
            return {
                ...prevState,
                languages : {
                    ...prevState.languages,
                    [lan] : {
                        ...prevState.languages[lan],
                        settings_modal: {
                            ...prevState.languages[lan].settings_modal,
                            blocks:arrTemp
                        }
                    }
                }
            }
        });
    }
    
    const loadInit = () => {
        const textarea = textareaRef.current.value;
        const initData = JSON.parse(textarea);
        if(textarea!='') {
            setFormData(initData);
            setLanguages(Object.keys(initData.languages));
        }
    }

    const loadBase = () => {
        setFormData(cookieJSONbase);
        setLanguages(Object.keys(cookieJSONbase.languages));
    }


    return (
        <>
        <div className="container my-4 py-4">
            <Textarea ref={textareaRef} handleChange={ loadInit}></Textarea>
            <button className="btn btn-primary mb-2" onClick={ loadInit }>Load JSON</button>
            <button className="btn btn-primary mb-2 ms-2" onClick={ loadBase }>Load BASE</button>
            <h2>base_settings</h2>
            <Input initial={formData.current_lang} id='current_lang'  type='text' desc='' label='Current Lang' handleChange={ handleChange } />
            <Checkbox id='autoclear_cookies' initial={formData.autoclear_cookies} desc='' label='Autoclear Cookies' handleChange={ handleChange } />
            <Checkbox id='page_scripts' initial={formData.page_scripts} desc='' label='Page Scripts' handleChange={ handleChange } />
            <Radio id='mode' initial={formData.mode} options={[{value:'opt-in'}, {value:'opt-out'}]} handleChange={ handleChange } />
            <Input id='delay' initial={formData.delay} type='number' desc='' label='Delay' handleChange={ handleChange } />
            <Select id='auto_language' initial={formData.auto_language} type='text' desc='' label='Auto Language' options={['null', 'browser', 'document']} handleChange={ handleChange } />
            <Checkbox id='force_consent' initial={formData.force_consent} desc='' label='Force Consent' handleChange={ handleChange } />
            <Checkbox id='hide_from_bots' initial={formData.hide_from_bots} desc='' label='Hide From Bots' handleChange={ handleChange } />
            <Checkbox id='remove_cookie_tables' initial={formData.remove_cookie_tables} desc='' label='Remove Cookie Table' handleChange={ handleChange } />

            <h2>gui_options</h2>
            <div className="p-3">
                <h4>consent_modal</h4>
                <Select id='gui_options_consent_modal_layout' initial={formData.gui_options.consent_modal.layout} subs={['consent_modal','layout']} type='text' desc='' label='Layout' options={[{value:'box'}, {value:'cloud'}, {value:'bar'}]} handleChange={ handleChange } />
                <Select id='gui_options_consent_modal_position' initial={formData.gui_options.consent_modal.position} subs={['consent_modal','position']} type='text' desc='' label='Position' options={[{name: 'top left'}, {name: 'top center'}, {name: 'top right'}, {name: 'middle left'}, {name: 'middle center'}, {name: 'middle right'}, {name: 'bottom left'}, {name: 'bottom center'}, {name: 'bottom right'}]} handleChange={ handleChange } />
                <Select id='gui_options_consent_modal_transition' initial={formData.gui_options.consent_modal.transition} subs={['consent_modal','transition']} type='text' desc='' label='Transition' options={[{name: 'zoom'}, {name: 'slide'}]} handleChange={ handleChange } />
                <Checkbox id='gui_options_consent_modal_swap_buttons' initial={formData.gui_options.consent_modal.swap_buttons} subs={['consent_modal','swap_buttons']} desc='' label='Swap Buttons' handleChange={ handleChange } />
                <h4>settings_modal</h4>
                <Select id='gui_options_settings_modal_layout'  initial={formData.gui_options.settings_modal.layout} subs={['settings_modal','layout']} type='text' desc='' label='Layout' options={[{value:'box'}, {value:'bar'}]} handleChange={ handleChange } />
                <Select id='gui_options_settings_modal_transition' initial={formData.gui_options.settings_modal.transition} subs={['settings_modal','transition']} type='text' desc='' label='Transition' options={[{name: 'zoom'}, {name: 'slide'}]} handleChange={ handleChange } />
            </div>

            <h2>languages</h2>

            <div className="form-inline">
                <div className="form-group mb-2">
                    <input ref={lanRef} type="text" className="form-control" defaultValue=''/>
                </div>
                <button className="btn btn-primary mb-2" onClick={ addLanguage }>Add Lang</button>
            </div>

            <Accordion id="languagesAccordion">
            {languages.map((lan,i) => {
                return (
                    <AccordionItem name={lan} index={i} key={i} headingFunction={()=>removeLanguage(lan)}>
                        <h3>consent_modal</h3>
                        <Input id={'languages_'+ lan +'_consent_modal_title'} initial={formData.languages[lan].consent_modal.title} subs={[[lan],'consent_modal','title']} type='text' desc='' label='Title' handleChange={ handleChange } />
                        <Input id={'languages_'+ lan +'_consent_modal_description'} initial={formData.languages[lan].consent_modal.description} subs={[[lan],'consent_modal','description']} type='text' desc='' label='Description' handleChange={ handleChange } />
                        <Input id={'languages_'+ lan +'_consent_modal_primary_btn_text'} initial={formData.languages[lan].consent_modal.primary_btn.text} subs={[[lan],'consent_modal','primary_btn', 'text']} type='text' desc='' label='Primary Button Text' handleChange={ handleChange } />
                        <Select id={'languages_'+ lan +'_consent_modal_primary_btn_role'} initial={formData.languages[lan].consent_modal.primary_btn.role} subs={[[lan],'consent_modal','primary_btn', 'role']} type='text' desc='' label='Primary Button Role' options={[ {value:'accept_selected'}, {value:'accept_all'}]} handleChange={ handleChange } />
                        <Input id={'languages_'+ lan +'_consent_modal_secondary_btn_text'} initial={formData.languages[lan].consent_modal.secondary_btn.text} subs={[[lan],'consent_modal','secondary_btn', 'text']} type='text' desc='' label='Secondary Button Text' handleChange={ handleChange } />
                        <Select id={'languages_'+ lan +'_consent_modal_secondary_btn_role'} initial={formData.languages[lan].consent_modal.secondary_btn.role} subs={[[lan],'consent_modal','secondary_btn', 'role']} type='text' desc='' label='Secondary Button Role' options={[ {value:'settings'}, {value:'accept_necessary'}]} handleChange={ handleChange } />
                        
                        <h3>settings_modal</h3>
                        <Input id={'languages_'+ lan +'_settings_modal_title'} initial={formData.languages[lan].settings_modal.title} subs={[[lan],'settings_modal','title']} type='text' desc='' label='Title' handleChange={ handleChange } />
                        <Input id={'languages_'+ lan +'_settings_modal_save_settings_btn'} initial={formData.languages[lan].settings_modal.save_settings_btn} subs={[[lan],'settings_modal','save_settings_btn']} type='text' desc='' label='Save Settings Btn' handleChange={ handleChange } />
                        <Input id={'languages_'+ lan +'_settings_modal_accept_all_btn'} initial={formData.languages[lan].settings_modal.accept_all_btn} subs={[[lan],'settings_modal','accept_all_btn']} type='text' desc='' label='Accept All Btn' handleChange={ handleChange } />
                        <Input id={'languages_'+ lan +'_settings_modal_reject_all_btn'} initial={formData.languages[lan].settings_modal.reject_all_btn} subs={[[lan],'settings_modal','reject_all_btn']} type='text' desc='' label='Reject All Btn' handleChange={ handleChange } />
                        <Input id={'languages_'+ lan +'_settings_modal_close_btn_label'} initial={formData.languages[lan].settings_modal.close_btn_label} subs={[[lan],'settings_modal','close_btn_label']} type='text' desc='' label='Close Btn Label' handleChange={ handleChange } />

                        <Input id={'languages_'+ lan +'_settings_modal_cookie_table_headers'} initial={formData.languages[lan].settings_modal.cookie_table_headers} subs={[[lan],'settings_modal','cookie_table_headers']} type='text' mode='array' desc='' label='Cookie Table Headers' handleChange={ handleChange } />

                        <h3>bocks <button className="btn btn-primary mb-2" onClick={ ()=>addBlock(lan, BLOCK_TEMPLATE) }>add text</button> <button className="btn btn-primary mb-2" onClick={ ()=>addBlock(lan, BLOCK_TABLE_TEMPLATE) }>add table</button></h3>
                        
                        {/*
                            title: '',
                            description: '',
                            toggle: {
                                value: '',     // your cookie category
                                enabled: false,
                                readonly: false
                            },
                            cookie_table: []
                        */}
                        {formData.languages[lan].settings_modal.blocks.map((block,i) => {
                            return <div key={i} className="mb-2">
                                <h5>Blocco {i+1} <span className="px-2 h6 align-self-center mb-0"  role="button" onClick={()=>removeBlock(lan,i)}><FontAwesomeIcon icon={faTrash} /></span></h5>
                                <Input id={'languages_'+ lan +'_settings_modal_blocks-'+i+'_title'} initial={formData.languages[lan].settings_modal.blocks[i].title} subs={[[lan],'settings_modal', ['blocks-'+i],'title']} type='text' desc='' label='Title' handleChange={ handleChange } />
                                <Input id={'languages_'+ lan +'_settings_modal_blocks-'+i+'_description'} initial={formData.languages[lan].settings_modal.blocks[i].description} subs={[[lan],'settings_modal', ['blocks-'+i],'description']} type='text' desc='' label='Description' handleChange={ handleChange } />
                                {block.toggle&&<><div className='p-4'>
                                    <h5>Toggle</h5>
                                    <Input id={'languages_'+ lan +'_settings_modal_blocks-'+i+'_toggle_value'} initial={formData.languages[lan].settings_modal.blocks[i].toggle.value} subs={[[lan],'settings_modal', ['blocks-'+i],'toggle','value']} type='text' desc='' label='Value' handleChange={ handleChange } />
                                    <Checkbox id={'languages_'+ lan +'_settings_modal_blocks-'+i+'_toggle_enabled'} initial={formData.languages[lan].settings_modal.blocks[i].toggle.enabled} subs={[[lan],'settings_modal', ['blocks-'+i],'toggle','enabled']} desc='' label='Enabled' handleChange={ handleChange } />
                                    <Checkbox id={'languages_'+ lan +'_settings_modal_blocks-'+i+'_toggle_readonly'} initial={formData.languages[lan].settings_modal.blocks[i].toggle.readonly} subs={[[lan],'settings_modal', ['blocks-'+i],'toggle','readonly']} desc='' label='Readonly' handleChange={ handleChange } />
                                </div>
                                <Input id={'languages_'+ lan +'_settings_modal_blocks-'+i+'_cookie_table'} initial={formData.languages[lan].settings_modal.blocks[i].cookie_table} subs={[[lan],'settings_modal', ['blocks-'+i],'cookie_table']} type='text' mode='arrayObj' desc='' label='Cookie Table' handleChange={ handleChange } />
                                </>}
                            </div>
                        })}
                    </AccordionItem>
                )
            })}
            </Accordion>
            <h2>code</h2>
            
            <code><pre>{JSON.stringify(formData, null, 2)}</pre></code>

            <button className="btn btn-primary" onClick={saveFile}>SALVA</button>
        </div>
        </>
    )
}


export default WithLayout(CookiesConfigurator);