import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

export const AccordionItem = ({name, index, children, headingFunction}) => {
    
    return (
        
        <div className="accordion-item">
            <h2 className="accordion-header  d-flex flex-row" id={"heading"+index}>
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#collapse"+index} aria-expanded="false" aria-controls={"collapse"+index}>
                {name}
            </button>
            <span className="px-2 h6 align-self-center mb-0"  role="button" onClick={headingFunction}><FontAwesomeIcon icon={faTrash} /></span>
            </h2>
            <div id={"collapse"+index} className="accordion-collapse collapse" aria-labelledby={"heading"+index} data-bs-parent={"#"+name}>
                <div className="accordion-body">
                    {children}
                </div>
            </div>
        </div>
    )
}


export const Accordion = ({id, children}) => {
    return <div className="accordion accordion" id={id}>{children}</div>
}
