import React from 'react'

const Checkbox = ({ id, desc, label, handleChange, subs, initial }) => {
  return (
    <>
        <div className="mb-3 form-check">
            <input type="checkbox" autoComplete="off" className="form-check-input" id={ id } name={ id } data-subs={subs} onClick={ handleChange } checked={initial} defaultChecked={initial}/>
            <label className="form-check-label" htmlFor={ id }>{ label }</label>
            {desc&&<div id={ 'desc_'+id} className="form-text">{ desc }</div>}
        </div>
    </>
  )
}

export default Checkbox